<script>
export default {
  name: 'ChildInfoDialog',

  props: ['child'],

  methods: {
    close () {
      this.$emit('close')
    },

    startMessage () {
      this.$emit('startMessage', this.child)
      this.close()
    },

    showNotes () {
      this.$emit('showNotes', this.child)
      this.close()
    },

    moveToArchive () {
      this.$emit('moveToArchive', this.child)
      this.close()
    }
  }
}
</script>

<template>
  <div class="dialog-wrapper">
    <div class="dialog-overlay" @click="close"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="dialog-header-text">
          {{ child.last_name }} {{ child.first_name }}
        </div>
        <BaseCloseButton @click="close" />
      </div>
      <div class="dialog-content">
        <table class="child-info">
          <tbody>
            <tr>
              <td class="child-info-label">ID</td>
              <td class="child-info-value">{{ child.id }}</td>
            </tr>
          </tbody>
        </table>
        <table class="child-info">
          <tbody>
            <tr>
              <td class="child-info-label">Родитель</td>
              <td class="child-info-value">{{ child.parent_full_name }}</td>
            </tr>
            <tr>
              <td class="child-info-label">Номер телефона</td>
              <td class="child-info-value">
                <a class="phone-number-link" :href="`tel:${ child.parent_phone_number }`">
                  {{ $utils.formatPhoneNumber(child.parent_phone_number) }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="child-info">
          <tbody>
            <tr>
              <td class="child-info-label">Баланс</td>
              <td class="child-info-value">
                {{ $utils.formatDecimal(child.balance) }} ₽
                <template v-if="child.attendances_left === 1">
                  <FontAwesomeIcon icon="exclamation-triangle" style="color: orange;" />
                </template>
                <template v-else-if="child.attendances_left === 0">
                  <FontAwesomeIcon icon="exclamation-triangle" style="color: var(--red);" />
                </template>
              </td>
            </tr>
            <tr>
              <td class="child-info-label">Не оплачено</td>
              <td class="child-info-value">
                {{ child.unpaid_attendance_count }}
                <template v-if="child.unpaid_attendance_count > 0">
                  <FontAwesomeIcon icon="exclamation-triangle" style="color: var(--red);" />
                </template>
              </td>
            </tr>
            <tr>
              <td class="child-info-label">Абонемент</td>
              <td class="child-info-value">
                <template v-if="child.subscription_attendances_left < 0">∞</template>
                <template v-else>
                  {{ child.subscription_attendances_left }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="menu">
        <a class="menu-item" @click="showNotes">
          Показать комментарии
        </a>
        <a class="menu-item" @click="startMessage">
          Написать сообщение
        </a>
        <a class="menu-item" :href="`tg://resolve?phone=${ $utils.trimPlus(child.parent_phone_number) }&profile`">
          Написать в Telegram
        </a>
        <a class="menu-item danger" @click="moveToArchive">
          Перенести в архив
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.child-info {
  width: 100%;
}

.child-info:not(:last-child) {
  margin-bottom: 0.5em;
}

.child-info-label {
  font-weight: bold;
  text-align: right;
  vertical-align: top;
  padding-right: 1em;
  width: 40%;
}

.phone-number-link {
  color: var(--dark-gray);
}

.menu {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  box-shadow: inset 0 0.2em 0.5em var(--light-overlay);
  background: var(--light-gray);

  padding: 0.5em 0;
}

.menu-item {
  color: inherit;
  text-decoration: none;

  padding: 0.8em 1.45em;

  font-weight: bold;
  text-align: center;
  text-shadow: 0 1px 0 white;

  cursor: pointer;
}

.menu-item:hover {
  background: var(--light-overlay);
}

.menu-item.danger {
  color: var(--red);
}
</style>
