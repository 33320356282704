<template>
  <div class="card">
    <div class="card-content">
      <div class="header">
        <div>
          <h2 class="header-text">
            {{ child.last_name }} {{ child.first_name }}
          </h2>
          <div class="header-subtext">
            {{ child.group.name }}
            <small v-if="child.kindergarten_group">
              / {{ child.kindergarten_group.name }}
            </small>
          </div>
        </div>
        <div class="header-id">
          ID: {{ child.id }}
        </div>
      </div>
      <div class="detail">
        <ChildInfo :child="child" />
        <div class="content">
          <div
            v-if="child.unpaid_attendance_count > 0"
            class="unpaid-warning"
          >
            <FontAwesomeIcon icon="exclamation-triangle" fixed-width />
            У вас {{ child.unpaid_attendance_count }}
            {{ $utils.pluralize(child.unpaid_attendance_count, 'неоплаченное посещение', 'неоплаченных посещения', 'неоплаченных посещений') }}
          </div>
          <div class="tab-links">
            <a
              :class="['tab-link', {active: currentTab === Tab.calendar}]"
              @click="currentTab = Tab.calendar"
            >
              Календарь
            </a>
            <a
              :class="['tab-link', {active: currentTab === Tab.list}]"
              @click="currentTab = Tab.list"
            >
              Темы уроков
            </a>
          </div>
          <BaseMonthSelector
            v-model:date="monthStart"
          >
            <AttendancesCalendar
              v-if="currentTab === Tab.calendar"
              :childID="child.id"
              :monthStart="monthStart"
            />
            <AttendancesList
              v-if="currentTab === Tab.list"
              :childID="child.id"
              :groupID="child.group.id"
              :monthStart="monthStart"
            />
          </BaseMonthSelector>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'

import AttendancesCalendar from '@/components/journal/parent/child/AttendancesCalendar.vue'
import AttendancesList from '@/components/journal/parent/child/AttendancesList.vue'
import ChildInfo from '@/components/journal/parent/child/ChildInfo.vue'

defineProps(['child'])

const Tab = {
  calendar: 'calendar',
  list: 'list'
}

const currentTab = ref(Tab.calendar)

const now = new Date()
const monthStart = ref(new Date(
  now.getFullYear(),
  now.getMonth(),
  1
))
</script>

<style scoped>
.card {
  width: 768px;

  margin: 1em 0;
  padding: 1em;

  border-radius: 1em;

  background-color: var(--medium-overlay);
}

.card-content {
  border-radius: 0.5em;
  overflow: hidden;
}

.header {
  padding: 2em;

  background-image: url('../../../../static/images/cloud.svg');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: var(--green);

  color: white;

  text-shadow: 0px 1px 1px var(--shadow);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-text {
  margin: 0;

  font-size: 2em;
}

.header-subtext {
  margin-top: 0.3em;
}

.header-id {
  font-size: 1.5em;
  font-weight: 700;

  opacity: 0.9;
}

.detail {
  display: flex;
}

.content {
  flex: 3;

  position: relative;
  padding: 2em;

  background-color: white;

  height: 780px;
}

.unpaid-warning {
  color: var(--red-darker);
  margin-bottom: 2em;
  text-align: center;
}

.tab-links {
  margin: 0 0 2em 0;
}

.tab-links .tab-link {
  padding: 0.6em 2em;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;

    padding: 0;
    margin: 0;

    border-radius: 0;
  }

  .card-content {
    border-radius: 0;
  }

  .detail {
    flex-direction: column;
  }
}
</style>
