<template>
  <div class="journal-page">
<!--    <ViberDialog-->
<!--      v-if="isViberDialogVisible"-->
<!--      @close="isViberDialogVisible = false"-->
<!--    />-->
    <PushNotifications :user="user" />
    <div
      v-if="!isPending && children && children.length > 0 && concerts && concerts.length > 0"
      class="card-list"
    >
      <ConcertCard
        v-for="concert in concerts"
        :concert="concert"
        :children="children"
        :key="concert.id"
        @purchaseTicket="fetchData"
      />
    </div>
    <div
      v-if="!isPending && children && children.length > 0 && items && items.length > 0"
      class="card-list"
    >
      <MerchCard
        v-for="item in items"
        :item="item"
        :children="children"
        :key="item.id"
        @purchase="fetchData"
      />
    </div>
    <BaseSpinner v-if="isPending"/>
    <template v-else-if="children && children.length > 0">
      <div v-if="children.length > 1" class="tab-links child-links">
        <a
          v-for="child in children"
          :key="child.id"
          :class="[
            'tab-link',
            'child-link',
            {'active': child.id === selectedChildID},
            {'inactive': !child.is_active}
          ]"
          @click="selectedChildID = child.id"
        >
          {{ child.first_name }}
        </a>
      </div>
      <div v-if="selectedChild" class="card-list">
        <ChildCard
          :child="selectedChild"
          :key="selectedChild.id"
        />
      </div>
    </template>
    <div v-else-if="children" class="alert-wrapper">
      <BaseAlert color="warning">
        <div>Ваши дети ещё не добавлены в базу.</div>
        <div>Если вы уверены, что это ошибка, обратитесь к администратору.</div>
      </BaseAlert>
    </div>
    <div v-if="texts" class="parent-controls">
      <a :href="texts.agreement" class="button no-background">Договор оферты</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { computed } from 'vue'

import ChildCard from '@/components/journal/parent/child/ChildCard.vue'
import ConcertCard from '@/components/journal/parent/concert/ConcertCard.vue'
import MerchCard from '@/components/journal/parent/merch/MerchCard.vue'
import PushNotifications from '@/components/journal/PushNotifications.vue'
// import ViberDialog from '@/components/journal/parent/child/ViberDialog.vue'

import api from '@/api'
import { getErrors } from '@/errors'
import eventBus from '@/eventBus'

export default {
  name: 'ParentPage',

  components: {
    ChildCard,
    ConcertCard,
    MerchCard,
    PushNotifications
    // ViberDialog
  },

  provide () {
    return {
      texts: computed(() => this.texts)
    }
  },

  props: ['user'],

  created () {
    this.fetchData().then(() => {
      if (this.children && this.children.length > 0) {
        this.selectedChildID = this.children[0].id
      }
    })

    this.fetchIsViberRegistered()

    eventBus.on('buySubscription', this.fetchData)
  },

  beforeUnmount () {
    eventBus.off('buySubscription', this.fetchData)
  },

  data () {
    return {
      isPending: false,

      children: null,
      concerts: null,
      items: null,
      texts: null,

      selectedChildID: null,

      isViberDialogVisible: false
    }
  },

  computed: {
    selectedChild () {
      return (
        this.selectedChildID &&
        this.children &&
        this.children.find(child => child.id === this.selectedChildID)
      )
    }
  },

  methods: {
    fetchData () {
      this.isPending = true

      return axios.all([
        api.get('/children/'),
        api.get('/concerts/'),
        api.get('/merch/items/'),
        api.get('/parent-journal/')
      ])
        .then(([children, concerts, items, texts]) => {
          this.children = children.data
          this.concerts = concerts.data
          this.items = items.data
          this.texts = texts.data
        }, error => {
          this.$toast.error(getErrors(error).detail)
        })
        .then(() => {
          this.isPending = false
        })
    },

    fetchIsViberRegistered () {
      api.get('/bots/viber/is-registered/')
        .then((response) => {
          this.isViberDialogVisible = !response.data.is_registered
        })
    }
  }
}
</script>

<style scoped>
.journal-page {
  position: relative;

  flex: 1;

  display: flex;
  flex-direction: column;
}

.child-link.inactive {
  color: var(--medium-gray);
}

.child-link.active {
  color: var(--dark-gray);
}

.card-list {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.parent-controls {
  position: relative;

  margin-bottom: 2em;

  align-self: center;

  display: flex;
  align-items: center;
}

.alert-wrapper {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
